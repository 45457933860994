import Cookies from "js-cookie";

const getToken = () => {
  const jwtToken = Cookies.get("auth_token");
  return jwtToken;
};

const setAuthToken = (token) => {
  Cookies.set("auth_token", token, {
    expires: 7,
    path: "/",
    domain:
      process.env.NEXT_PUBLIC_ENV === "local"
        ? "localhost"
        : ".invitedekho.com",
    secure: true,
    sameSite: "Strict",
  });
};

const removeToken = () => {
  Cookies.remove("auth_token", {
    path: "/",
    domain:
      process.env.NEXT_PUBLIC_ENV === "local"
        ? "localhost"
        : ".invitedekho.com",
  });
};

export { getToken, setAuthToken, removeToken };
