import {clsx} from "clsx"
import moment from "moment";
import {twMerge} from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}


export  const addWorkingDays = (startDate, days) => {
  const endDate = new Date(startDate);
  let daysAdded = 0;
  while (daysAdded < days) {
    endDate.setDate(endDate.getDate() + 1);
    if (endDate.getDay() !== 0) {
      daysAdded++;
    }
  }
  return moment(endDate).format("DD-MM-YYYY");
};