let templates = [
  {
    id: 1,
    templateId: "1-a",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/1-a.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp1-a.webp`,
    url: "/template/1",
    isSelected: false,
    theme: {
      bgColor: "#9CAB8D",
      textColor: "#000",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    images: {
      weddingDayURL: `url("https://wedding.nicdark.com/wedding-day/wp-content/uploads/sites/7/2023/03/gr-leaf-10.png")`,
      waterColorIMG: "url('/images/bg-watercolor-02.jpg')",
      swiperSlide1: "/images/venders/avatar-02.png",
      swiperSlide2: "/images/venders/logo-white-01.png",
      swiperSlide3: "/images/venders/logo-white-02.png",
      swiperSlide4: "/images/venders/logo-white-03.png",
      swiperSlide5: "/images/venders/logo-white-04.png",
      swiperSlide6: "/images/venders/logo-white-06.png",
      paral02: "url('/images/c-paral-02.jpg')",
      waterColor02: "url('/images/bg-watercolor-02.jpg')",
      waterColor: "url('/images/bg-watercolor.jpg')",
      double1:
        "https://template-assets-sv.s3.amazonaws.com/template1/SNY_1945.JPG",
      gallery10:
        "https://template-assets-sv.s3.amazonaws.com/template1/DSC_4388.JPG",
      leaf03: "url('/images/gr-leaf-03.png')",
    },
  },
  {
    id: 2,
    templateId: "1-b",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/1-b.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp1-b.webp`,

    url: "/template/2",
    isSelected: false,
    theme: {
      bgColor: "#c21919",
      textColor: "#fff",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    images: {
      weddingDayURL: `url("https://wedding.nicdark.com/wedding-day/wp-content/uploads/sites/7/2023/03/gr-leaf-10.png")`,
      waterColorIMG: "url('/images/bg-watercolor-02-c21919.jpg')",
      swiperSlide1: "/images/venders/avatar-02.png",
      swiperSlide2: "/images/venders/logo-white-01.png",
      swiperSlide3: "/images/venders/logo-white-02.png",
      swiperSlide4: "/images/venders/logo-white-03.png",
      swiperSlide5: "/images/venders/logo-white-04.png",
      swiperSlide6: "/images/venders/logo-white-06.png",
      paral02: "url('/images/c-paral-02.jpg')",

      waterColor02: "url('/images/bg-watercolor-02-c21919.jpg')",
      waterColor: "url('/images/bg-watercolor-c21919.jpg')",
      double1:
        "https://template-assets-sv.s3.amazonaws.com/template1/SNY_1945.JPG",
      gallery10:
        "https://template-assets-sv.s3.amazonaws.com/template1/DSC_4388.JPG",
      leaf03: "url('/images/gr-leaf-03.png')",
    },
  },
  {
    id: 3,
    templateId: "1-c",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/1-c.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp1-c.webp`,

    url: "/template/3",
    isSelected: false,
    theme: {
      bgColor: "#efc0fe",
      textColor: "#000",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    images: {
      weddingDayURL: `url("https://wedding.nicdark.com/wedding-day/wp-content/uploads/sites/7/2023/03/gr-leaf-10.png")`,
      waterColorIMG: "url('/images/bg-watercolor-02-efc0fe.jpg')",
      swiperSlide1: "/images/venders/avatar-02.png",
      swiperSlide2: "/images/venders/logo-white-01.png",
      swiperSlide3: "/images/venders/logo-white-02.png",
      swiperSlide4: "/images/venders/logo-white-03.png",
      swiperSlide5: "/images/venders/logo-white-04.png",
      swiperSlide6: "/images/venders/logo-white-06.png",
      paral02: "url('/images/c-paral-02.jpg')",
      waterColor02: "url('/images/bg-watercolor-02-efc0fe.jpg')",
      waterColor: "url('/images/bg-watercolor-efc0fe.jpg')",
      double1:
        "https://template-assets-sv.s3.amazonaws.com/template1/SNY_1945.JPG",
      gallery10:
        "https://template-assets-sv.s3.amazonaws.com/template1/DSC_4388.JPG",
      leaf03: "url('/images/gr-leaf-03.png')",
    },
  },
  {
    id: 4,
    templateId: "1-d",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/1-d.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp1-d.webp`,

    url: "/template/4",
    isSelected: false,
    theme: {
      bgColor: "#ffe54c",
      textColor: "#000",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    images: {
      weddingDayURL: `url("https://wedding.nicdark.com/wedding-day/wp-content/uploads/sites/7/2023/03/gr-leaf-10.png")`,
      waterColorIMG: "url('/images/bg-watercolor-02-ffe54c.jpg')",
      swiperSlide1: "/images/venders/avatar-02.png",
      swiperSlide2: "/images/venders/logo-white-01.png",
      swiperSlide3: "/images/venders/logo-white-02.png",
      swiperSlide4: "/images/venders/logo-white-03.png",
      swiperSlide5: "/images/venders/logo-white-04.png",
      swiperSlide6: "/images/venders/logo-white-06.png",
      paral02: "url('/images/c-paral-02.jpg')",
      waterColor02: "url('/images/bg-watercolor-02-ffe54c.jpg')",
      waterColor: "url('/images/bg-watercolor-ffe54c.jpg')",
      double1:
        "https://template-assets-sv.s3.amazonaws.com/template1/SNY_1945.JPG",
      gallery10:
        "https://template-assets-sv.s3.amazonaws.com/template1/DSC_4388.JPG",
      leaf03: "url('/images/gr-leaf-03.png')",
    },
  },
  {
    id: 5,
    templateId: "2-a",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/2-a.webp`,

    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp2-a.webp`,

    url: "/template/5",
    isSelected: false,
    color: "blue",
    theme: {
      bgColor: "#80A0C2",
      textColor: "#000",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    bg01: "url('/assets/templete/2/bg-01.jpg')",
    bg02: "url('/assets/templete/2/bg-02.jpg')",
    bg03: "url('/assets/templete/2/bg-03.jpg')",
    bg04: "url('/assets/templete/2/bg-04.jpg')",
    bg05: "url('/assets/templete/2/bg-05.jpg')",
    bg06: "url('/assets/templete/2/bg-06.jpg')",
    bg07: "url('/assets/templete/2/bg-07.png')",
    bg08: "url('/assets/templete/2/bg-08.jpg')",
    bg09: "url('/assets/templete/2/bg-09.jpg')",
    bg11: "url('/assets/templete/2/bg-11.jpg')",
    bg12: "url('/assets/templete/2/bg-12.jpg')",

    paral06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 6,
    templateId: "2-b",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/2-b.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp2-b.webp`,
    url: "/template/6",
    isSelected: false,
    color: "blue",
    theme: {
      bgColor: "#c21919",
      textColor: "#fff",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    bg01: "url('/assets/templete/2/bg-01-c21919.jpg')",
    bg02: "url('/assets/templete/2/bg-02-c21919.jpg')",
    bg03: "url('/assets/templete/2/bg-03-c21919.jpg')",
    bg04: "url('/assets/templete/2/bg-04-c21919.jpg')",
    bg05: "url('/assets/templete/2/bg-05-c21919.jpg')",
    bg06: "url('/assets/templete/2/bg-06-c21919.jpg')",
    bg07: "url('/assets/templete/2/bg-07.png')",
    bg08: "url('/assets/templete/2/bg-08-c21919.jpg')",
    bg09: "url('/assets/templete/2/bg-09-c21919.jpg')",
    bg11: "url('/assets/templete/2/bg-11-c21919.jpg')",
    bg12: "url('/assets/templete/2/bg-12-c21919.jpg')",

    paral06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 7,
    templateId: "2-c",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/2-c.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp2-c.webp`,
    url: "/template/7",
    isSelected: false,
    color: "blue",
    theme: {
      bgColor: "#efc0fe",
      textColor: "#000",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    bg01: "url('/assets/templete/2/bg-01-efc0fe.jpg')",
    bg02: "url('/assets/templete/2/bg-02-efc0fe.jpg')",
    bg03: "url('/assets/templete/2/bg-03-efc0fe.jpg')",
    bg04: "url('/assets/templete/2/bg-04-efc0fe.jpg')",
    bg05: "url('/assets/templete/2/bg-05-efc0fe.jpg')",
    bg06: "url('/assets/templete/2/bg-06-efc0fe.jpg')",
    bg07: "url('/assets/templete/2/bg-07.png')",
    bg08: "url('/assets/templete/2/bg-08-efc0fe.jpg')",
    bg09: "url('/assets/templete/2/bg-09-efc0fe.jpg')",
    bg11: "url('/assets/templete/2/bg-11-efc0fe.jpg')",
    bg12: "url('/assets/templete/2/bg-12-efc0fe.jpg')",

    paral06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 8,
    templateId: "2-d",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/2-d.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp2-d.webp`,
    url: "/template/8",
    isSelected: false,
    color: "blue",
    theme: {
      bgColor: "#ffe54c",
      textColor: "#000",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    bg01: "url('/assets/templete/2/bg-01-ffe54c.jpg')",
    bg02: "url('/assets/templete/2/bg-02-ffe54c.jpg')",
    bg03: "url('/assets/templete/2/bg-03-ffe54c.jpg')",
    bg04: "url('/assets/templete/2/bg-04-ffe54c.jpg')",
    bg05: "url('/assets/templete/2/bg-05-ffe54c.jpg')",
    bg06: "url('/assets/templete/2/bg-06-ffe54c.jpg')",
    bg07: "url('/assets/templete/2/bg-07.png')",
    bg08: "url('/assets/templete/2/bg-08-ffe54c.jpg')",
    bg09: "url('/assets/templete/2/bg-09-ffe54c.jpg')",
    bg11: "url('/assets/templete/2/bg-11-ffe54c.jpg')",
    bg12: "url('/assets/templete/2/bg-12-ffe54c.jpg')",

    paral06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 9,
    templateId: "3-a",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/3-a.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp3-a.webp`,
    url: "/template/9",
    isSelected: false,
    theme: {
      bgColor: "#9CAB8D",
      textColor: "#000",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    couple: "/assets/templete/3/couple.png",
    frame09: "/assets/templete/3/frame-09.png",
    frame05: "/assets/templete/3/frame-05.png",
    frame06: "/assets/templete/3/frame-06.png",
    frame08: "/assets/templete/3/frame-08.png",
    frame: "/assets/templete/3/frame.png",
    greyLight: "url('/assets/templete/3/img-grey-light.png')",
    occasions3: [
      {
        id: 1,
        stp: "1",
        img: "/assets/templete/3/occasions/icon-04.png",
        functionName: " The Ceremony ",
        functionInfo:
          " The Ceremony is the moment when two people officially become partners for life.",
      },
      {
        id: 2,
        stp: "2",
        img: "/assets/templete/3/occasions/icon-06.png",
        functionName: "Lunch Together ",
        functionInfo:
          " Moment to relax, enjoy good food, and create unforgettable memories, a cherished tradition.",
      },
      {
        id: 3,
        stp: "3",
        img: "/assets/templete/3/occasions/icon-05.png",
        functionName: "Photo Boo ",
        functionInfo:
          " Fun and interactive way to capture memories with silly props and other.",
      },
    ],
    stepss: [
      {
        id: 1,
        stp: "1",
        img: "https://template-assets-sv.s3.amazonaws.com/template3/IMG-202039997-WA0037.jpg",
        functionName: " Wedding Ceremony ",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/Indira+Gandhi+International+Airport/@28.527554,77.0438314,11z/data=!4m6!3m5!1s0x390d1b85fc2a2d89:0xbef376182c43ed9d!8m2!3d28.5563529!4d77.39996963!16zL20vMDEyNDQ0?entry=ttu",
        functionInfo:
          " The wedding ceremony is the heart of any celebration. It's the moment when the couple exchange vows and commit to spending the rest of their lives together.",
      },
      {
        id: 2,
        stp: "2",
        functionName: " Lunch Time ",
        img: "/assets/templete/3/itenary/gallery-16-1.jpg",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/Mazaar+Restaurant/@28.5688605,77.2328845,15z/data=!4m6!3m5!1s0x390ce3ad97db2cb5:0xdce447161ff5833!8m2!3d28.5701715!4d77.2443807!16s%2Fg%2F1v7pwvx2?entry=ttu",
        functionInfo:
          " After the wedding ceremony, it's time for lunch! This is a time for guests to relax and enjoy a delicious meal together, while catching up with old friends and making new ones.",
      },
      {
        id: 3,
        stp: "3",
        functionName: " Party with Music ",
        img: "/assets/templete/3/itenary/gallery-12-1.jpg",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/C+R+Park+Market+No.1/@28.5350621,77.2431712,13.75z/data=!4m6!3m5!1s0x390ce3d83e0cb4b7:0xb7ece1334b216b84!8m2!3d28.5401153!4d77.2486233!16s%2Fg%2F1wk4bfx6?entry=ttu",
        functionInfo:
          " The party with music is the perfect way to celebrate the newlyweds and their love story. This is a time for guests to let loose, hit the dance floor, and enjoy some great music.",
      },
    ],
  },
  {
    id: 10,
    templateId: "3-b",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/3-b.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp3-b.webp`,
    url: "/template/10",
    isSelected: false,
    theme: {
      bgColor: "#c21919",
      textColor: "#fff",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    couple: "/assets/templete/3/couple.png",
    frame09: "/assets/templete/3/frame-09-c21919.png",
    frame05: "/assets/templete/3/frame-05-c21919.png",
    frame06: "/assets/templete/3/frame-06-c21919.png",
    frame08: "/assets/templete/3/frame-08-c21919.png",
    frame: "/assets/templete/3/frame-c21919.png",
    greyLight: "url('/assets/templete/3/img-grey-light-c21919.png')",
    avatars: [
      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortWaved&accessoriesType=Blank&hairColor=Brown&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=Hoodie&clotheColor=Blue01&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Default&skinColor=Tanned",

      "https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light",

      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortWaved&accessoriesType=Prescription02&hairColor=Black&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=Hoodie&clotheColor=Heather&eyeType=Happy&eyebrowType=RaisedExcitedNatural&mouthType=Smile&skinColor=Pale",

      "https://avataaars.io/?avatarStyle=Transparent&topType=LongHairMiaWallace&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=ShirtScoopNeck&clotheColor=Heather&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Default&skinColor=Pale",

      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairDreads01&accessoriesType=Blank&hairColor=Black&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=ShirtCrewNeck&clotheColor=PastelYellow&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Default&skinColor=Pale",

      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Blank&hairColor=Black&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=CollarSweater&clotheColor=Red&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Twinkle&skinColor=Light",
    ],

    occasions3: [
      {
        id: 1,
        stp: "1",
        img: "/assets/templete/3/occasions/icon-04.png",
        functionName: " The Ceremony ",
        functionInfo:
          " The Ceremony is the moment when two people officially become partners for life.",
      },
      {
        id: 2,
        stp: "2",
        img: "/assets/templete/3/occasions/icon-06.png",
        functionName: "Lunch Together ",
        functionInfo:
          " Moment to relax, enjoy good food, and create unforgettable memories, a cherished tradition.",
      },
      {
        id: 3,
        stp: "3",
        img: "/assets/templete/3/occasions/icon-05.png",
        functionName: "Photo Boo ",
        functionInfo:
          " Fun and interactive way to capture memories with silly props and other.",
      },
    ],
    stepss: [
      {
        id: 1,
        stp: "1",
        img: "https://template-assets-sv.s3.amazonaws.com/template3/IMG-202039997-WA0037.jpg",
        functionName: " Wedding Ceremony ",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/Indira+Gandhi+International+Airport/@28.527554,77.0438314,11z/data=!4m6!3m5!1s0x390d1b85fc2a2d89:0xbef376182c43ed9d!8m2!3d28.5563529!4d77.39996963!16zL20vMDEyNDQ0?entry=ttu",
        functionInfo:
          " The wedding ceremony is the heart of any celebration. It's the moment when the couple exchange vows and commit to spending the rest of their lives together.",
      },
      {
        id: 2,
        stp: "2",
        functionName: " Lunch Time ",
        img: "/assets/templete/3/itenary/gallery-16-1.jpg",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/Mazaar+Restaurant/@28.5688605,77.2328845,15z/data=!4m6!3m5!1s0x390ce3ad97db2cb5:0xdce447161ff5833!8m2!3d28.5701715!4d77.2443807!16s%2Fg%2F1v7pwvx2?entry=ttu",
        functionInfo:
          " After the wedding ceremony, it's time for lunch! This is a time for guests to relax and enjoy a delicious meal together, while catching up with old friends and making new ones.",
      },
      {
        id: 3,
        stp: "3",
        functionName: " Party with Music ",
        img: "/assets/templete/3/itenary/gallery-12-1.jpg",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/C+R+Park+Market+No.1/@28.5350621,77.2431712,13.75z/data=!4m6!3m5!1s0x390ce3d83e0cb4b7:0xb7ece1334b216b84!8m2!3d28.5401153!4d77.2486233!16s%2Fg%2F1wk4bfx6?entry=ttu",
        functionInfo:
          " The party with music is the perfect way to celebrate the newlyweds and their love story. This is a time for guests to let loose, hit the dance floor, and enjoy some great music.",
      },
    ],
  },
  {
    id: 11,
    templateId: "3-c",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/3-c.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp3-c.webp`,
    url: "/template/11",
    isSelected: false,
    theme: {
      bgColor: "#efc0fe",
      textColor: "#000",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    couple: "/assets/templete/3/couple.png",
    frame09: "/assets/templete/3/frame-09-efc0fe.png",
    frame05: "/assets/templete/3/frame-05-efc0fe.png",
    frame06: "/assets/templete/3/frame-06-efc0fe.png",
    frame08: "/assets/templete/3/frame-08-efc0fe.png",
    frame: "/assets/templete/3/frame-efc0fe.png",
    greyLight: "url('/assets/templete/3/img-grey-light-efc0fe.png')",
    avatars: [
      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortWaved&accessoriesType=Blank&hairColor=Brown&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=Hoodie&clotheColor=Blue01&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Default&skinColor=Tanned",

      "https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light",

      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortWaved&accessoriesType=Prescription02&hairColor=Black&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=Hoodie&clotheColor=Heather&eyeType=Happy&eyebrowType=RaisedExcitedNatural&mouthType=Smile&skinColor=Pale",

      "https://avataaars.io/?avatarStyle=Transparent&topType=LongHairMiaWallace&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=ShirtScoopNeck&clotheColor=Heather&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Default&skinColor=Pale",

      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairDreads01&accessoriesType=Blank&hairColor=Black&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=ShirtCrewNeck&clotheColor=PastelYellow&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Default&skinColor=Pale",

      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Blank&hairColor=Black&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=CollarSweater&clotheColor=Red&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Twinkle&skinColor=Light",
    ],

    occasions3: [
      {
        id: 1,
        stp: "1",
        img: "/assets/templete/3/occasions/icon-04.png",
        functionName: " The Ceremony ",
        functionInfo:
          " The Ceremony is the moment when two people officially become partners for life.",
      },
      {
        id: 2,
        stp: "2",
        img: "/assets/templete/3/occasions/icon-06.png",
        functionName: "Lunch Together ",
        functionInfo:
          " Moment to relax, enjoy good food, and create unforgettable memories, a cherished tradition.",
      },
      {
        id: 3,
        stp: "3",
        img: "/assets/templete/3/occasions/icon-05.png",
        functionName: "Photo Boo ",
        functionInfo:
          " Fun and interactive way to capture memories with silly props and other.",
      },
    ],
    stepss: [
      {
        id: 1,
        stp: "1",
        img: "https://template-assets-sv.s3.amazonaws.com/template3/IMG-202039997-WA0037.jpg",
        functionName: " Wedding Ceremony ",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/Indira+Gandhi+International+Airport/@28.527554,77.0438314,11z/data=!4m6!3m5!1s0x390d1b85fc2a2d89:0xbef376182c43ed9d!8m2!3d28.5563529!4d77.39996963!16zL20vMDEyNDQ0?entry=ttu",
        functionInfo:
          " The wedding ceremony is the heart of any celebration. It's the moment when the couple exchange vows and commit to spending the rest of their lives together.",
      },
      {
        id: 2,
        stp: "2",
        functionName: " Lunch Time ",
        img: "/assets/templete/3/itenary/gallery-16-1.jpg",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/Mazaar+Restaurant/@28.5688605,77.2328845,15z/data=!4m6!3m5!1s0x390ce3ad97db2cb5:0xdce447161ff5833!8m2!3d28.5701715!4d77.2443807!16s%2Fg%2F1v7pwvx2?entry=ttu",
        functionInfo:
          " After the wedding ceremony, it's time for lunch! This is a time for guests to relax and enjoy a delicious meal together, while catching up with old friends and making new ones.",
      },
      {
        id: 3,
        stp: "3",
        functionName: " Party with Music ",
        img: "/assets/templete/3/itenary/gallery-12-1.jpg",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/C+R+Park+Market+No.1/@28.5350621,77.2431712,13.75z/data=!4m6!3m5!1s0x390ce3d83e0cb4b7:0xb7ece1334b216b84!8m2!3d28.5401153!4d77.2486233!16s%2Fg%2F1wk4bfx6?entry=ttu",
        functionInfo:
          " The party with music is the perfect way to celebrate the newlyweds and their love story. This is a time for guests to let loose, hit the dance floor, and enjoy some great music.",
      },
    ],
  },
  {
    id: 12,
    templateId: "3-d",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/3-d.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp3-d.webp`,
    url: "/template/12",
    isSelected: false,
    theme: {
      bgColor: "#ffe54c",
      textColor: "#000",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    couple: "/assets/templete/3/couple.png",
    frame09: "/assets/templete/3/frame-09-ffe54c.png",
    frame05: "/assets/templete/3/frame-05-ffe54c.png",
    frame06: "/assets/templete/3/frame-06-ffe54c.png",
    frame08: "/assets/templete/3/frame-08-ffe54c.png",
    frame: "/assets/templete/3/frame-ffe54c.png",
    greyLight: "url('/assets/templete/3/img-grey-light-ffe54c.png')",
    avatars: [
      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortWaved&accessoriesType=Blank&hairColor=Brown&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=Hoodie&clotheColor=Blue01&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Default&skinColor=Tanned",

      "https://avataaars.io/?avatarStyle=Transparent&topType=LongHairStraight&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Light",

      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortWaved&accessoriesType=Prescription02&hairColor=Black&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=Hoodie&clotheColor=Heather&eyeType=Happy&eyebrowType=RaisedExcitedNatural&mouthType=Smile&skinColor=Pale",

      "https://avataaars.io/?avatarStyle=Transparent&topType=LongHairMiaWallace&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=ShirtScoopNeck&clotheColor=Heather&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Default&skinColor=Pale",

      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairDreads01&accessoriesType=Blank&hairColor=Black&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=ShirtCrewNeck&clotheColor=PastelYellow&eyeType=Default&eyebrowType=RaisedExcitedNatural&mouthType=Default&skinColor=Pale",

      "https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Blank&hairColor=Black&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=CollarSweater&clotheColor=Red&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Twinkle&skinColor=Light",
    ],
    occasions3: [
      {
        id: 1,
        stp: "1",
        img: "/assets/templete/3/occasions/icon-04.png",
        functionName: " The Ceremony ",
        functionInfo:
          " The Ceremony is the moment when two people officially become partners for life.",
      },
      {
        id: 2,
        stp: "2",
        img: "/assets/templete/3/occasions/icon-06.png",
        functionName: "Lunch Together ",
        functionInfo:
          " Moment to relax, enjoy good food, and create unforgettable memories, a cherished tradition.",
      },
      {
        id: 3,
        stp: "3",
        img: "/assets/templete/3/occasions/icon-05.png",
        functionName: "Photo Boo ",
        functionInfo:
          " Fun and interactive way to capture memories with silly props and other.",
      },
    ],
    stepss: [
      {
        id: 1,
        stp: "1",
        img: "https://template-assets-sv.s3.amazonaws.com/template3/IMG-202039997-WA0037.jpg",
        functionName: " Wedding Ceremony ",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/Indira+Gandhi+International+Airport/@28.527554,77.0438314,11z/data=!4m6!3m5!1s0x390d1b85fc2a2d89:0xbef376182c43ed9d!8m2!3d28.5563529!4d77.39996963!16zL20vMDEyNDQ0?entry=ttu",
        functionInfo:
          " The wedding ceremony is the heart of any celebration. It's the moment when the couple exchange vows and commit to spending the rest of their lives together.",
      },
      {
        id: 2,
        stp: "2",
        functionName: " Lunch Time ",
        img: "/assets/templete/3/itenary/gallery-16-1.jpg",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/Mazaar+Restaurant/@28.5688605,77.2328845,15z/data=!4m6!3m5!1s0x390ce3ad97db2cb5:0xdce447161ff5833!8m2!3d28.5701715!4d77.2443807!16s%2Fg%2F1v7pwvx2?entry=ttu",
        functionInfo:
          " After the wedding ceremony, it's time for lunch! This is a time for guests to relax and enjoy a delicious meal together, while catching up with old friends and making new ones.",
      },
      {
        id: 3,
        stp: "3",
        functionName: " Party with Music ",
        img: "/assets/templete/3/itenary/gallery-12-1.jpg",
        dateTime: "5/jun/2023 | 12:30pm",
        location:
          "https://www.google.com/maps/place/C+R+Park+Market+No.1/@28.5350621,77.2431712,13.75z/data=!4m6!3m5!1s0x390ce3d83e0cb4b7:0xb7ece1334b216b84!8m2!3d28.5401153!4d77.2486233!16s%2Fg%2F1wk4bfx6?entry=ttu",
        functionInfo:
          " The party with music is the perfect way to celebrate the newlyweds and their love story. This is a time for guests to let loose, hit the dance floor, and enjoy some great music.",
      },
    ],
  },
  {
    id: 13,
    templateId: "4-a",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/4-a.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp4-a.webp`,
    url: "/template/13",
    isSelected: false,
    theme: {
      bgColor: "#edf3f8",
      textColor: "#80A0C2",
      textBgColor: "#80A0C2",
    },
    tabs: ["Premium"],

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/4/time/clock-bg.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 14,
    templateId: "4-b",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/4-b.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp4-b.webp`,
    url: "/template/14",
    isSelected: false,
    crossPrice: "7999 ₹",
    price: "99 ₹",
    theme: {
      bgColor: "#c21919",
      textColor: "#c21919",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/4/time/clock-bg-c21919.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-c21919.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-c21919.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-c21919.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-c21919.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-c21919.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 15,
    templateId: "4-c",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/4-c.webp`,
    url: "/template/15",
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp4-c.webp`,
    isSelected: false,
    theme: {
      bgColor: "#efc0fe",
      textColor: "#efc0fe",
      textBgColor: "#000",
    },
    tabs: ["Premium"],

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/4/time/clock-bg-efc0fe.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-efc0fe.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-efc0fe.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-efc0fe.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-efc0fe.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-efc0fe.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 16,
    templateId: "4-d",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/4-d.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp4-d.webp`,
    url: "/template/16",
    isSelected: false,
    theme: {
      bgColor: "#ffe54c",
      textColor: "#ffe54c",
      textBgColor: "#000",
    },
    tabs: ["Premium"],

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/4/time/clock-bg-ffe54c.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 17,
    templateId: "5-a",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/5-a.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp5-a.webp`,
    url: "/template/17",
    isSelected: false,
    theme: {
      bgColor: "#fff",
      textColor: "#B2A56A",
      textBgColor: "#B2A56A",
      themeColor: "#fff",
    },
    tabs: ["Basic"],
  },
  {
    id: 18,
    templateId: "6-a",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/6-a.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp6-a.webp`,
    url: "/template/18",
    isSelected: false,
    theme: {
      bgColor: "#9CAB8D",
      textColor: "#222",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/4/time/clock-bg-ffe54c.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 19,
    templateId: "6-b",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/6-b.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp6-b.webp`,
    url: "/template/19",
    isSelected: false,
    theme: {
      bgColor: "#c21919",
      textColor: "#fff",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/4/time/clock-bg-ffe54c.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 20,
    templateId: "6-c",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/6-c.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp6-c.webp`,
    url: "/template/20",
    isSelected: false,
    theme: {
      bgColor: "#efc0fe",
      textColor: "#222",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/4/time/clock-bg-ffe54c.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 21,
    templateId: "6-d",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/6-d.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp6-d.webp`,
    url: "/template/21",
    isSelected: false,
    theme: {
      bgColor: "#ffe54c",
      textColor: "#222",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/4/time/clock-bg-ffe54c.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 22,
    templateId: "7-a",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/7-a.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp7-a.webp`,
    url: "/template/22",
    isSelected: false,
    theme: {
      bgColor: "#9CAB8D",
      textColor: "#222",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    bg_leaf: "/assets/templete/7/bgImages/9CAB8D.png",

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/7/time/clock-bg-9CAB8D.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 23,
    templateId: "7-b",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/7-b.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp7-b.webp`,
    url: "/template/23",
    isSelected: false,
    theme: {
      bgColor: "#c21919",
      textColor: "#222",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    bg_leaf: "/assets/templete/7/bgImages/c21919.png",
    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/7/time/clock-bg-c21919.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 24,
    templateId: "7-c",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/7-c.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp7-c.webp`,
    url: "/template/24",
    isSelected: false,
    theme: {
      bgColor: "#efc0fe",
      textColor: "#222",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    bg_leaf: "/assets/templete/7/bgImages/efc0fe.png",
    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/7/time/clock-bg-efc0fe.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 25,
    templateId: "7-d",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/7-d.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp5-a.webp`,
    url: "/template/25",
    isSelected: false,
    theme: {
      bgColor: "#ffe54c",
      textColor: "#222",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],
    bg_leaf: "/assets/templete/7/bgImages/ffe54c.png",
    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/7/time/clock-bg-ffe54c.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
  {
    id: 26,
    templateId: "8-a",
    templateName: "Classic",
    crossPrice: "7999 ₹",
    price: "99 ₹",
    templateImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/8-a.webp`,
    socialImage: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/template-thumbnails/social-share/temp8-a.webp`,
    url: "/template/26",
    isSelected: false,
    theme: {
      bgColor: "#6B7280",
      textColor: "#ffe54c",
      textBgColor: "#fff",
    },
    tabs: ["Premium"],

    shape3: "/assets/templete/4/sliderImages/shape3.png",
    shape4: "/assets/templete/4/sliderImages/shape4.png",
    icon02: "/images/icon-02.png",
    clock_bg: "/assets/templete/4/time/clock-bg-ffe54c.png",
    couple_man: "/assets/templete/4/coupleinfo/cople-man.jpg",
    couple_image: "/assets/templete/4/coupleinfo/couple-image.jpg",
    frame_shape: "/assets/templete/4/coupleinfo/fram-shape-ffe54c.png",
    frame_woment: "/assets/templete/4/coupleinfo/couple-woment.jpg",
    section_title2: "/assets/templete/4/section-title2-ffe54c.png",
    story1: "/assets/templete/4/milestone/story-1.jpg",
    timeline_shape: "/assets/templete/4/milestone/timeline-shape-ffe54c.png",
    event_shape1: "/assets/templete/4/Itinerary/event-shape-1-ffe54c.png",
    event_shape2: "/assets/templete/4/Itinerary/event-shape-2-ffe54c.png",
    paral_06: "/assets/templete/2/paral_06.jpg",
  },
];
const footerString = "Designed and developed by";
const galleryUrl =
  "https://www.invitedekho.com/template/bab297fcab1a8fde1158e702b2115aa917bb7e203b9158486bf741d9f77c0b92/gallery/";

export function getTemplates() {
  templates = templates.reverse();
  let arrangedTemplates = [];
  for (let subId of ["a", "b", "c", "d"]) {
    arrangedTemplates = [
      ...arrangedTemplates,
      ...templates.filter(
        (template) => template.templateId.split("-")[1] === subId
      ),
    ];
  }
  return arrangedTemplates;
}

const templatesData = getTemplates();

export { templates, templatesData, footerString, galleryUrl };
