import HTTPClientHandler from "@/services/HTTPClientHandler";

const http = new HTTPClientHandler();


const getWishlist = async () => {
  const response = await http.get({
    url: "/wishlist/user",
    isSecured: true,
    // payload: filter,
  });
  return response.data;
};

const postWishlist = async (payload) => {
  var response = await http.post({
    url: `/wishlist`,
    payload: payload,
    isSecured: true,
  });
  return response.data;
};
const deleteWishlist = async (id) => {
  var response = await http.delete({
    url: `/wishlist/${id}`,
    isSecured: true,
  });
  return response.data;
};

export {getWishlist, postWishlist, deleteWishlist};
